import axios from "axios";

const asyncdecryptImage = (data) => {
  const key = "RZM15kr$Oh=IxG#o";
  const encrypted_data = data;
  const KEY_arr = new TextEncoder().encode(key);
  const decrypted_data = new Uint8Array(encrypted_data);
  for (let i = 0; i < 100; i++) {
    decrypted_data[i] ^= KEY_arr[i % KEY_arr.length];
  }
  const src = URL.createObjectURL(
    new Blob([decrypted_data], { type: "image/png" })
  );

  return src;
};

export const getImgSrc = async (img) => {
  try {
    const response = await axios.get(img, {
      responseType: "arraybuffer",
    });
    const imgSrc = asyncdecryptImage(response.data); // 解密图片地址
    return imgSrc;
  } catch (error) {
    console.log(error);
  }
};
