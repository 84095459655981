import request from "./request";

const domain = process.env.REACT_APP_DOMAIN || window.location.origin;

export const getDomainDetail = (deviceId) => {
  return request.post("/api/v1/latestAddress", {
    time: new Date().getTime(),
    deviceId,
    domain,
  });
};
