
import FingerprintJS from "@fingerprintjs/fingerprintjs";
export const generateDeviceId = () => {
  return new Promise((resolve, reject) => {
    FingerprintJS.load().then((fp) => {
      fp.get().then((result) => {
        const visitorId = result.visitorId;
        resolve(visitorId);
      });
    });
  });
};
export default generateDeviceId
