import "./App.css";
import { useEffect, useState } from "react";
import { getDomainDetail } from "./api";
import { generateDeviceId, getImgSrc, setFavicons } from "./utils";
import { CopyToClipboard } from "react-copy-to-clipboard";
import  buttonImg from "./asset/img/button.png"
import textImg from "./asset/img/lastestWeb.png"

function App() {
  const [domainData, setDomainData] = useState({});
  const getDomainDetailData = async () => {
    const deviceId = await generateDeviceId();
    const res = await getDomainDetail(deviceId);
    const logo = res.data.data.logo;
    const logoSrc = await getImgSrc(logo);
    setFavicons(logoSrc);
    const title = res.data.data.title;
    var appTitle = document.querySelector("title");
    appTitle.innerHTML = title;
    setDomainData({ ...res.data.data, logoSrc });
  };
  useEffect(() => {
    getDomainDetailData();
  }, []);

  const onCopy = () => {
    alert("复制成功！");
  };

  return (
    <div className="App">
      <div className="logo-container">
        <div className="logo">
          <img src={domainData.logoSrc} alt="logo" />
        </div>
        <div className="app-name">{domainData.app_name}</div>
      </div>
      
      <div>
        <div className="web-site">
          <img src={textImg} alt="" />
          </div>
        <div  className="btn-container">
          <CopyToClipboard
            text={domainData.address}
            onCopy={onCopy}
          >
            <div className="copy-button">
              <img src={buttonImg} alt="" />
            </div>
          </CopyToClipboard>
        </div>
      </div>
    </div>
  );
}

export default App;
